.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url("./images/background.jpg");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-div {
  margin-top: 50px;
}

.card {
  width: 30rem;
}

.card-title {
  font-weight: bold;
  font-size: large;
}

.normal-text {
  font-weight: normal;
  text-align: justify;
}

.card-body {
  color: black;
}

.mint-btn {
  position: relative;
  display: block;
  top: 6px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 900 !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content {
  background: rgba(4, 6, 125, 255) !important;
  padding: 20px;
  border-radius: 20px !important;
  max-width: 500px;
  width: 100%;
  color: white;
}

.close {
  position: relative;
  left: 445px;
  bottom: 10px;
  width: fit-content;
  color: white;
}

.close:hover {
  color: white;
}

ul {
  position: relative;
  z-index: 1;
  margin-top: 56px;
  flex-direction: column;
  gap: 19px;
  list-style-type: none;
  padding-left: 0;
  margin-top: 10px;
}

li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.wallet-img {
  width: 28px;
  height: 28px;
  margin-left: 100px;
  margin-bottom: 8px;
  float: left;
}

.spinner {
  justify-content: center;
  align-content: center;
  /* padding-top: 100px; */
  color: white !important;
}

.page-title {
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 0;
  position: relative;
  top: 30px;
  z-index: 1;
}
.error-img {
  width: 30%;
  height: 30%;
  align-content: center;
  margin-left: 162px;
  margin-top: 0;
  margin-bottom: 20px;
}

.error-message {
  margin-bottom: 40px;
}

.txt {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-size: large;
  color: white;
}
.custom-btn {
  height: 66px;
  justify-content: flex-start;
  gap: 10px;
  letter-spacing: -0.48px !important;
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  border-radius: 20px !important;
  border-color: blueviolet;
  border-width: 1px;
}
.success {
  color: green;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}
.error {
  color: red;
  font-size: 22px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.text {
  color: white;
  text-align: center;
}
